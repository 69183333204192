import { useEffect, useState } from "react";
import { clearStorages } from "../../utils/Storage";
import { getCookie, clearCookies } from "../../utils/Cookie";
import { clearSessions } from "../../utils/Session";
import { isObject } from "../../utils/Objects";
import { jwtDecode } from "jwt-decode";

export default function UserAuth() {
  //informa se usuário está autenticado
  const [authenticated, setAuthenticated] = useState(false);
  //exibe ou não o loading em tela
  const [loading, setLoading] = useState(true);
  //pega a string token
  const token = getCookie("TOKEN");
  //extrai o token se for verdadeiro
  const jsonToken = token !== "" ? jwtDecode(token) : null;
  //versão do app
  const version = "1.3.1";

  /**
   * Destrói a sessão do usuário logado
   * e redireciona para a tela de login
   * @returns
   */
  const destroyAccess = () => {
    clearStorages();
    clearSessions();
    clearCookies();
    window.location.href = "/login";
    return;
  };

  /**
   * Calcula o tempo que o usuário
   * está inativo sem mexer na tela
   */
  const calcInatividade = () => {
    const intervalo = 60 * 60 * 1000;
    var timeout = setTimeout(inativo, intervalo);

    function actividade() {
      clearTimeout(timeout);
      timeout = setTimeout(inativo, intervalo);
    }

    function inativo() {
      return destroyAccess();
    }

    ["mousemove", "keyup", "touchmove", "wheel"].forEach((ev) => {
      window.addEventListener(ev, actividade, false);
    });
  };

  useEffect(() => {
    try {
      if (jsonToken !== null && isObject(jsonToken)) {
        const timestamp = parseInt(
          new Date().getTime().toString().slice(0, 10)
        );

        //quando inválido
        if (jsonToken.exp - timestamp <= 0) {
          return destroyAccess();
        }

        calcInatividade();
        setAuthenticated(true);
      }
    } catch (error) {
      return destroyAccess();
    }

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  return {
    authenticated,
    token: jsonToken,
    loading,
    version,
  };
}
